import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "styled-components";
import { Link, createSearchParams } from "react-router-dom";
import './footerStyle.css'
import androidApp from "../../../assets/img/logo/android.png";
import iosApp from "../../../assets/img/logo/apple.png";
import { SocialCreators } from './Social/store';
import Loader from "../../../Common/Loader/Loader";
import { CityCreators } from "./City/store";
import { SubcategoryCreators } from "./Subcategory/store"; 
import moment from "moment/moment";


const useAuth = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return true;
  } else {
    return false;
  }
}

export const Footer = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const { socialList, socialloading } = useSelector((state) => state.socialData);

  const { CityList } = useSelector((state) => state.cityData);

  const { subcategoryList, subcategoryloading } = useSelector((state) => state.subcategoryData);

  


  useEffect(() => {
    dispatch(SocialCreators.getSocialList());
    dispatch(CityCreators.getCityList());
    dispatch(SubcategoryCreators.getSubcategoryList());
  }, [])

    // console.log('CityList', CityList)

  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6">
              <h6>Popular Cities</h6>
              {/* <p className="text-justify">
                {socialList?.footer_title ? socialList?.footer_title : ''}</p> */}
              <div className="jsx-f49a36b77adcf1d3 footer_rowbox footer_row_1 footer_services pb-30 pl-45 pr-45">
                <div className="jsx-f49a36b77adcf1d3 font12  mt-10 fw400 footer-links">
                
                    {
                      CityList?.map((item, index) => {
                        return (
                          <>
                            <Link key={index} to={`${auth ? `/jobs?service=${item?.name}` : "/login"}`} className="jsx-f49a36b77adcf1d3 anchor link">{item?.name} </Link> |&nbsp; 

                          </>
                        )
                      })
                    }
                  </div>
                </div>
                <h6>Popular Categories</h6>
              {/* <p className="text-justify">
                {socialList?.footer_title ? socialList?.footer_title : ''}</p> */}
              <div className="jsx-f49a36b77adcf1d3 footer_rowbox footer_row_1 footer_services pb-30 pl-45 pr-45">
                <div className="jsx-f49a36b77adcf1d3 font12  mt-10 fw400 footer-links">
                
                    {
                      subcategoryList?.map((item, index) => {
                        return (
                          <>
                            <Link key={index} to={`${auth ? `/jobs?service=${item?.slug}` : "/login"}`} className="jsx-f49a36b77adcf1d3 anchor link">{item?.name} </Link> |&nbsp; 

                          </>
                        )
                      })
                    }
                  </div>
                </div>
            </div>

            <div className="col-xs-6 col-md-3">
              <h6>Quick Links</h6>
              <ul className="footer-links">
                <li><Link to="/privacy&policy">Privacy & Policy</Link></li>
                <li><Link to="/term&conditions">Term & Conditions</Link></li>
              </ul>
            </div>

            <div className="col-xs-6 col-md-3">
              <h6>Download App</h6>
              <ul className="footer-links">
                <li><Link to={socialList?.android} target="_blank"><img src={androidApp} className="img-fluid app-img" width="100" /></Link></li>
                {socialList?.ios ?
                  <li><Link to={socialList?.ios} target="_blank"><img src={iosApp} className="img-fluid app-img" width="100" /></Link></li>
                  : ''}
              </ul>
            </div>
          </div>
          <hr />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-6 col-xs-12">
              <p className="copyright-text">Copyright &copy; 2023-{moment().year()} All Rights Reserved.
                {/* <Link to="/"> Serviceora</Link>. */}
              </p>
            </div>

            <div className="col-md-4 col-sm-6 col-xs-12">
              <ul className="social-icons">
                <li>
                  {socialloading ? <Loader width="1rem" /> :
                    socialList?.facebook ?
                      <Link className="facebook" to={socialList?.facebook} target='_blank'><i className="fab fa-facebook"></i></Link>
                      : ''
                  }
                </li>
                <li>
                  {socialloading ? <Loader width="1rem" /> :
                    socialList?.instagram ?
                      <Link className="instagram" to={socialList?.instagram} target='_blank'><i className="fab fa-instagram"></i></Link>
                      : ''
                  }
                </li>
                <li>
                  {socialloading ? <Loader width="1rem" /> :
                    socialList?.linkedin ?
                      <Link className="linkedin" to={socialList?.linkedin} target='_blank'><i className="fab fa-linkedin"></i></Link>
                      : ''
                  }
                </li>
                <li>
                  {socialloading ? <Loader width="1rem" /> :
                    socialList?.twitter ?
                      <Link className="twitter" to={socialList?.twitter} target='_blank'><i className="fab fa-twitter"></i></Link>
                      : ''
                  }
                </li>
                <li>
                  {socialloading ? <Loader width="1rem" /> :
                    socialList?.youtube ?
                      <Link className="youtube" to={socialList?.youtube} target='_blank'><i className="fab fa-youtube"></i></Link>
                      : ''
                  }
                </li>
              </ul>
            </div>
          </div>
        </div>

      </footer>
      {/* <div className="cookie-consent">

        <span> Tap to download from the Google Play Store.</span>
        <div className=" ml-2 d-flex align-items-center justify-content-center g-2">
          <button className="allow-button mr-1"><Link to="https://play.google.com/store/apps/details?id=serviceora.app" target="_blank" style={{color:'#FFFFFF'}}>Download App</Link></button>
          <button className="allow-button">cancel</button>
        </div>


      </div> */}
    </>
  );
};
export const IconSpanSocial = styled.li`
float: left;
list-style-type: none;
margin-left: 8px;
font-size: 20px;
`;
