import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import blankImg from "../../../../assets/img/logo/blank.png";
import { useNavigate, Link } from "react-router-dom";
import serviceorasvg from "../../../../assets/img/logo/serviceora.svg";
import { Creators } from "../../../auth/store";
import { rootConfig, apiEndPoints } from "../../../../services/axios/endPoints";
import ModalComponent from "../../../../Common/Modal/Modal";
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Offline, Online } from "react-detect-offline";
import { SocialCreators } from "../Social/store";
import Loader from "../../../../Common/Loader/Loader";
import './style.css'
import { UserNotificationCreators } from "../../Dashboard/UserNotification/store";
import moment from "moment/moment";
import { WebService } from "../../../../services/axios/webServices";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import { HeadNotification } from "../HeadNotification/HeadNotification";
import PushNotificationsContainer from "../../../../firebaseNotifications/AndroidFCM"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faCircle } from "@fortawesome/free-solid-svg-icons";
import Button from 'react-bootstrap-button-loader';
import { ArrowRight, CCircle } from 'react-bootstrap-icons';




const useAuth = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return true;
  } else {
    return false;
  }
};

const Header = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const dispatch = useDispatch();

  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [collapseMenu, setCollapsedMenu] = useState(false);
  const [isDown, setIsDown] = useState(false)
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const handleShow1 = () => setShow1(true);


  // const handleShow1 = () => {
  //   return (
  //     <>
  //     console.log('yes ')
  //       <PushNotificationsContainer />
  //     </>
  //   )
  // }





  const { profileData } = useSelector(
    (state) => state.profileData
  );

  const { socialList, socialloading } = useSelector((state) => state.socialData);


  const { loginmsg, userData } = useSelector((state) => state.loginData);
  const { usernotificationList, usernotificationloading } = useSelector((state) => state.userNotificationData);

  useEffect(() => {
    document.body.classList.remove('menu-opened');
  }, [])

  const toggleMenu = () => {
    document.body.classList.toggle('menu-opened');
    setCollapsedMenu(collapseMenu)
  }


  const handleLogout = () => {
    setIsModalVisible(false)
    dispatch(Creators.onLogout());
    navigate('/');
  };

  const handleClearAll = async() => {
    try {
      const response = await WebService.post(apiEndPoints.updateNotificationList,
        JSON.stringify(),
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userData.token}`
          }
        }
      )
      if (response.status === 200) {
        dispatch(UserNotificationCreators.getUserNotificationList());

      } else if (response.response.status === 404) {
      }
    }
    catch (e) {
      const { error } = sagaCatchBlockHandling(e);
      console.log('error500', error.response.status)

    }
  }

  useEffect(() => {
    dispatch(SocialCreators.getSocialList());
    dispatch(UserNotificationCreators.getUserNotificationList());
  }, [])

  const openNotificationPopup = () => {
    if(isDown == false)
    {
      setIsDown(true)
    }
    else{
      setIsDown(false)
    }
  }


  return (
    <>
      {show1 == true ? 
      <>
        <PushNotificationsContainer />
      </>
      : ''}
      <ModalComponent
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        handleLogout={handleLogout}
        title="Logout Confirmation"
      />
      <div className="header-top">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
              <div className="left-top">
                <ul>
                  <li>
                    {
                      <>
                        <i className="fas fa-phone-volume top-icon"></i>
                        <Link to={`${`tel:${socialList?.contact}`}`}>{socialList?.contact}</Link>
                      </>
                    }
                  </li>
                  <li>
                    {
                      <>
                        <i className="fas fa-envelope top-icon"></i>
                        <Link to={`${`mail:${socialList?.email}`}`}>
                          {socialList?.email}
                        </Link>
                      </>
                    }
                  </li>

                </ul>
              </div>
            </div>
            <div className="col-md-5">
              {/* <marquee>Download <Link to='https://play.google.com/store/apps/details?id=serviceora.app' target="_blank" style={{color:'#26292c'}}>Serviceora App</Link>  & login device to get a web and device push notifications related your skilled.</marquee> */}
            </div>
            <div className="col-md-3">
              <div className="right-top">
                <ul>
                  {
                    socialList?.facebook ?
                      <li>
                        <Link target="_blank" to={socialList?.facebook}>
                          <i className="fab fa-facebook-f top-icons"></i>
                        </Link>
                      </li>
                      : ''

                  }
                  {
                    socialList?.instagram ?
                      <li>
                        <Link target="_blank" to={socialList?.instagram}>
                          <i className="fab fa-instagram top-icons"></i>
                        </Link>
                      </li>
                      : ''
                  }
                  {
                    socialList?.linkedin ?
                      <li>
                        <Link target="_blank" to={socialList?.linkedin}>
                          <i className="fab fa-linkedin-in top-icons"></i>
                        </Link>
                      </li>
                      : ''
                  }
                  {
                    socialList?.twitter ?
                      <li>
                        <Link target="_blank" to={socialList?.twitter}>
                          <i className="fab fa-twitter top-icons"></i>
                        </Link>
                      </li>
                      : ''
                  }
                  {
                    socialList?.youtube ?
                      <li>
                        <Link target="_blank" to={socialList?.youtube}>
                          <i className="fab fa-youtube top-icons"></i>
                        </Link>
                      </li>
                      : ''
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <header className="header">
        <nav className="navbar navbar-expand-lg header-nav">
          <div className="container-fluid">
            <div className="navbar-header">
              <Link id="mobile_btn" to="#" onClick={toggleMenu}>
                <span className="bar-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </Link>
              <Link to="/" className="navbar-brand logo">
                <img src={serviceorasvg} className="img-fluid" alt="Serviceora" style={{ maxHeight: "50px" }} />
              </Link>
              {auth ? ( 
                <Link to="/user/dashboard" id="mobile_btn"  style={{position:'relative', marginRight:'15px', marginTop:'14px', backgroundColor:'#ff9c27', color:'#FFFFFF', border:'2px solid #ff9c27', padding:'3px', borderRadius:'3px'}} >
                    Dashboard
                </Link>
               ) : 
              <Link to="/login" id="mobile_btn"  className="nav-link header-login" style={{position:'relative', marginRight:'15px', marginTop:'14px', color:'#03b2cb', border:'1px groove #03b2cb', padding:'5px', borderRadius:'3px'}}  data-tour="step-1" >
                  LogIn
              </Link>  
            }
              
              <Link id="mobile_btn" onClick={handleShow} style={{position:'relative', marginRight:'10px'}}>
                  <span className="user-img"><i className="fa fa-bell" style={{color:'#26292c',fontSize:'16px', marginTop:'20px'}}></i> <span className="badge badge-pill" style={{color: 'white',position: 'absolute', background: '#009DA6', borderRadius: '50%'}}>{usernotificationList?.notifications?.filter(notify => {
                        return notify.read === 0;
                      })?.length}</span></span>
              </Link>
            </div>
            <div className="main-menu-wrapper">

              <div className="menu-header">
                <Link className="menu-logo" to="/">
                  <img src={serviceorasvg} className="img-fluid" alt="Serviceora" />
                </Link>
                <Link id="menu_close" className="menu-close" to="#" onClick={toggleMenu}>
                  <i className="fas fa-times"></i>
                </Link>
              </div>
              


              <ul className="main-nav">
                <li className="">
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/jobs">Jobs</Link>
                </li>

                {auth ? (
                  <>
                    <li className="login-link">
                      <Link to="/user/dashboard">My Dashboard</Link>
                    </li>
                    <li className="login-link">
                      <Link to="/user/service/list" style={{textDecoration:'underline'}}>Find Service</Link>
                    </li>
                    <li
                      className="login-link"
                    // onClick={() => setIsModalVisible(true)}
                    >
                      <Link className="" onClick={handleLogout}>Logout</Link>
                    </li>
                    
                  </>
                ) :
                  <li className="login-link">
                    <Link to="/login">Login / Signup</Link>
                  </li>
                }
                {
                  socialList?.blog ?
                    <li className="has-submenu ">
                      <Link to={socialList?.blog} target="blank">Blog</Link>
                    </li>
                    : ''
                }

                <li className="has-submenu ">
                  <Link to="/contact-us">Contact us</Link>
                </li>
                <li className="has-submenu ">
                  <Link to="/about-us">About us</Link>
                </li>
                <li className="has-submenu ">
                  <Link to="/user/service/list" style={{textDecoration:'underline', color:'#009da6'}}>Find Service</Link>
                </li>
              </ul>


            </div>
            

            
            {auth ? (
              <>
                <ul className="nav header-navbar-rht"  data-tour="step-1">
                  <li className="nav-item">
                    <Link to="/user/job/create" className="nav-link header-login">
                      Create Post
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/user/profile-settings" className="nav-link header-register">Add Business</Link>
                  </li>
                </ul>
                <ul className="nav header-navbar-rht main-nav navbar-nav ml-auto">
                <li className="nav-item dropdown logged-item has-submenu  ">
                <Link
                  to="#"
                  className="nav-link"
                  onClick={handleShow}
                >
                  <span className="user-img">
                    <i className="fa fa-bell"></i> <span className="badge badge-pill" style={{ color: 'white', position: 'absolute', background: 'rgb(255, 156, 39)', borderRadius: '50%', marginTop: '10px' }}>{usernotificationList?.notifications?.filter(notify => {
                      return notify.read === 0;
                    })?.length}</span>
                  </span>
                </Link>
                 <HeadNotification handleClose={handleClose} show={show} setShow={setShow} handleShow={handleShow} usernotificationList={usernotificationList} usernotificationloading={usernotificationloading} openNotificationPopup={openNotificationPopup} handleClearAll={handleClearAll} isDown={isDown} userData={userData}/>
                  </li>
                  <li className="nav-item dropdown has-arrow logged-item has-submenu" data-tour="step-2">
                    <Link
                      to="#"
                      className="dropdown-toggle nav-link"
                      data-bs-toggle="dropdown"
                    >
                      <span className="user-img">

                        <img
                          className="rounded-circle me-2"
                          src={
                            `${profileData.length > 0
                              ? rootConfig.base_url + profileData?.[0]?.profile
                              : blankImg
                            }`
                          }
                          width="31"
                          alt={profileData.length > 0
                            ? userData?.name.toLocaleUpperCase()
                            : userData?.name?.toLocaleUpperCase()}
                        />
                        {profileData.length > 0
                          ? userData?.name.toLocaleUpperCase()
                          : userData?.name?.toLocaleUpperCase()}
                      </span>
                    </Link>
                    <ul className="submenu">
                      <li className="">
                        <Link to="/user/dashboard">My Dashboard</Link>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => setIsModalVisible(true)}
                      >
                        <Link className="">Logout</Link>
                      </li>
                    </ul>
                  </li>
                </ul>
              </>
            ) : (
              <ul className="nav header-navbar-rht" data-tour="step-1">
                <li className="nav-item">
                  <Link to="/login" className="nav-link header-login">
                    Log In
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/register" className="nav-link header-register">Create Account</Link>
                </li>
              </ul>
            )}
          </div>
        </nav>
      </header>
    </>
  )
}

export default Header