import React, { useEffect, useState } from "react";
import { useSearchParams, useParams, Link } from "react-router-dom";
import Loader from "../../../../Common/Loader/Loader";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import toast, { Toaster } from "react-hot-toast";
import { ToastSuccess } from "../../../../Common/ToastStyle/Toast";
import logoservicesvg from "../../../../assets/img/logo/serviceora.svg";

const Verifyemail = () => {
  const { token } = useParams();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [emailToken, setEmailToken] = useState({
    token: token
  })

  useEffect(() => {
    verifyEmailSet();
  }, [])

  const verifyEmailSet = async () => {
    try {
      const response = await WebService.post(apiEndPoints.VerifyEmail,
        JSON.stringify(emailToken)
      );
      if (response.status === 200) {
        setResult(response.data);
      } else if (response.response.status === 404) {
        setResult(response.response.data);
      } else {
        toast.error("something went wrong!", {
          position: "top-center",
        });
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);

      console.log(error, "error000");
    }
  };


  return (
    <>
      <Toaster />

      <div className="content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-10">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title" style={{ color: "#009DA6" }}><img src={logoservicesvg} className="img-fluid" alt="Serviceora" style={{ maxHeight: "30px" }} /> &nbsp; Serviceora, Email Verification </h4>
                </div>
                <div className="card-body">
                  <blockquote>
                    {
                      result.status === true ? (
                        <>
                          <div className="alert alert-success alert-dismissible fade show" role="alert">
                            <strong>Success!</strong> {result.msg}
                            {/* <button type="button" className="close" data-bs-dismiss="alert" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button> */}
                          </div>
                        </>
                      )
                        :
                        <>
                          <div className="alert alert-danger alert-dismissible fade show" role="alert">
                            <strong>Alert!</strong> {result.msg}
                            {/* <button type="button" className="close" data-bs-dismiss="alert" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button> */}
                          </div>
                        </>
                    }
                  </blockquote>
                  <blockquote className="blockquote mb-0">
                    <p className="mb-0">
                      Congratulation, Your email is verified successfully. <Link to="/login" className="active" style={{ fontSize: '15px', color: "rgb(0, 157, 166)", textDecoration: 'underline' }}>Click here</Link> to join us.
                    </p>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verifyemail;
