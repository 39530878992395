import React, { useState, useRef, useCallback, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthService from "../../services/axios/auth.servies";
import { useDispatch, useSelector } from "react-redux";
import { Creators as AuthCreator, Creators } from "./store/index";
import { debounce } from "lodash";
import logoserviceora from "../../assets/img/logo/serviceora.svg";
import { WebService } from "../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../services/axios/endPoints";
// import { setAccessTokenToHeader } from "../../services/axios/helper";
import { LocalNotifications } from '@capacitor/local-notifications';
import OtpInput from 'react-otp-input';
import './otpverify.css'
import Button from 'react-bootstrap-button-loader';




import {
  getUserDataFromLocal,
  setUserDataToLocal,
} from "../../shared/utility/helper";
import toast, { Toaster } from "react-hot-toast";
import { ErrorP } from "../../Common/Modal/Modal";
import { isEmail } from "../../shared/utility/regex";
import Loader from "../../Common/Loader/Loader";
import { SocialCreators } from "../Pages/Landing/Social/store";
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { sagaCatchBlockHandling } from "../../store/utility/sagaErrorHandling";





export const Otpverify = () => {
  const userData = getUserDataFromLocal();
  const { token } = useParams();
  var encodePhoneA = token;
  var endcodePhoneB = atob(encodePhoneA);


  const { error, loading, authenticated, loginmsg } = useSelector(
    (state) => state.loginData
  );
  const form = useRef();
  const checkBtn = useRef();
  const [otp, setOtp] = useState('');
  const [loginInput, setLoginInput] = useState({ otp_code: '', phone_number: endcodePhoneB, device_token: localStorage.getItem('device_token'), android_device_token: localStorage.getItem('android_device_token') });
  const [message, setMessage] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorList, seterrorList] = useState([])
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  

  const handleLogin = async () => {
    dispatch(AuthCreator.loginOTP(loginInput));
  };

  const debounceOnLogin = useCallback(debounce(handleLogin, 400), [
    handleLogin,
  ]);

  useEffect(() => {

    setLoginInput({ ...loginInput, otp_code: otp, phone_number: endcodePhoneB });
    // console.log('loginInput', loginInput)

    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      
  
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
  
    return () => {

      clearInterval(interval);
    };
  }, [seconds]);


  const handleSubmit = (e) => {
    e.preventDefault();
    if (loginInput.otp_code !== "" && loginInput.phone_number !== "") {
      debounceOnLogin();
      if (error !== "" && loginmsg === "") {
        toast.error(error, { position: "top-center" });
      }
    } else {
      setIsEmpty(true);
    }
  };

  useEffect(() => {
    if (authenticated) {
      navigate("/");
    }
  }, [authenticated]);

  useEffect(() => {
    dispatch(Creators.onLoginOTPSuccess({ msg: "", authenticated: false }));
  }, [loginInput]);

  const { socialList, socialloading } = useSelector((state) => state.socialData);

  useEffect(() => {
    dispatch(SocialCreators.getSocialList());
  }, []);

  useEffect(() => {
    if (error) {
      toast?.error(error, { position: "top-center" });
    }
  }, [error]);

  const OpenHomePage = () => {
    navigate('/')
  }

  const handleResendOTP = async (e) => {
    e.preventDefault();
    try {
      const response = await WebService.post(
        apiEndPoints.resendOTP,
        JSON.stringify({phone_number:endcodePhoneB}),
      );
      if (response.status === 200) {
        toast.success(response.data.msg, {
          position: "top-center",
        });
      } else if (response.response.status === 404) {
        toast.error(response.response.data.msg, {
          position: "top-center",
        });
      }
      else {
        toast.error('something went wrong!', {
          position: "top-center",
        });
      }
    } catch (e) {
      const error = sagaCatchBlockHandling(e);
      toast.error(error?.error, {
        position: "top-right",
      });
    }
  }

  return (
    <>
      <Toaster />
      <div className="bg-pattern-style" style={{ background: 'url(' + rootConfig?.base_url + socialList?.loginImg + ') no-repeat' }}>
        <div className="content">
          <div className="account-content">
            <div className="account-box">
              <div className="login-right">
                <div className="login-header text-center">
                  <img src={logoserviceora} className="img-fluid" alt="Serviceora" style={{ maxHeight: "50px" }} /> <br />
                  <h3 className="text-center">
                    <span onClick={() => OpenHomePage()} style={{ cursor: "pointer", lineHeight: "2" }}>Serviceora</span>
                  </h3>
                </div>
                <div className="container height-100 d-flex justify-content-center align-items-center">
                  <div className="position-relative">
                    <div className="card p-2 text-center">
                      <h6>Please enter the one time password <br /> to verify your account</h6>
                      <div> <span>A code has been sent to</span> <small>{endcodePhoneB.replace(/.(?=.{4,}$)/g, '*')}</small> </div>
                      <form onSubmit={handleSubmit}>
                      
                        <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
                        <div className="form-group">
                          <OtpInput
                            inputStyle="inputStyle"
                            value={otp}
                            onChange={(e) => {
                              setOtp(e);
                              setLoginInput({ ...loginInput, otp_code: otp, phone_number: endcodePhoneB });
                            }}
                            isInputSecure='true'
                            numInputs={4}
                            renderSeparator={<span>-</span>}
                            renderInput={(props) => <input {...props} />}
                          />


                        </div>
                        </div>
                        <div className="mt-4"> 
                        <Button className="btn btn-primary login-btn col-6" type="submit" loading={loading ? true : false}>Verify!</Button>
                        {/* <button className="btn btn-course col-6" type="submit">Verify</button> */}
                         </div>
                        <br/>
                        {seconds > 0 || minutes > 0 ? (
                          <p style={{color:'red'}}>
                            Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
                            {seconds < 10 ? `0${seconds}` : seconds}
                          </p>
                        ) : (
                          ''
                        )}
                      </form>
                    </div>
                    {seconds > 0 || minutes > 0 ? (
                      ''
                    ) : (
                      <div className="card-2">
                        <div className="justify-content-center align-items-center text-center"> <span>Didn't get the code</span>
                          <Link className="text-decoration-underline text-color-green ms-3" onClick={handleResendOTP}>Resend OTP </Link> 
                        </div>
                      </div>
                    )}
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
