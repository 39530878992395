import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SideBar } from "../SideBar";
import { getUserDataFromLocal } from "../../../../shared/utility/helper";
import { ProfileCreators } from "../../Dashboard/Profile/store";
import { WebService } from "../../../../services/axios/webServices";
import { apiEndPoints, rootConfig } from "../../../../services/axios/endPoints";
import toast, { Toaster } from "react-hot-toast";
import { ToastSuccess } from "../../../../Common/ToastStyle/Toast";
import { sagaCatchBlockHandling } from "../../../../store/utility/sagaErrorHandling";
import Loader from "../../../../Common/Loader/Loader";
import moment from "moment/moment";
import logoserviceora from "../../../../assets/img/logo/serviceora.svg";
import { Modal } from "react-bootstrap";
import { ErrorP } from "../../../../Common/Modal/Modal";
import JsPDF from 'jspdf';


export const ShowJobInvoice = () => {
    const { order_no } = useParams();
    const userData = getUserDataFromLocal();
    const [loading, setLoading] = useState(false)
    const [invoice, setInvoice] = useState({})
    const [isInvoiceModal, setIsInvoiceModal] = useState(false)
    const [jobTitle, setJobTitle] = useState('')
    const [input, setInput] = useState({})
    const [errorList, seterrorList] = useState([])

    const navigate = useNavigate();
    const { profileData } = useSelector((state) => state.profileData);
    // console.log('order_no', order_no)

    const jobInvoiceShow = async () => {
        try {
            setLoading(true);
            const response = await WebService.get(apiEndPoints.jobInvoiceShow, {
                headers: {
                    Authorization: `Bearer ${userData.token}`,
                },

                params: {
                    order_no: order_no,
                },
            });
            const { data } = response;
            // console.log(data.data)
            if (response.status === 200) {
                setInvoice(data.data);
                setLoading(false);
            } else if (response.response.status === 429) {
                toast.danger(response.response.data.message, {
                    position: "top-right",
                });
            } else {
                throw response;
                setLoading(false);
            }

        } catch (e) {
            const error = sagaCatchBlockHandling(e);
            setLoading(false);

        }
    }

    useEffect(() => {
        jobInvoiceShow();
    }, []);


    const Print_div = (e) => {
        e.preventDefault()
        // document.querySelector('body').classList.remove('profile-sidebar')
        let printContents = document.getElementById('serviceora_print_invoice').innerHTML;
        let originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        window.print();
        document.body.innerHTML = originalContents;
        navigate(0)
    }



    const handleInvoiceChange = (e) => {
        const { name, value } = e.target;
        setInput({ ...input, [name]: value });
    }

    const updateInvoice = (order_no, jobTitle) => {
        setJobTitle(jobTitle)
        setIsInvoiceModal(true)
        setInput({ ...input, 'order_no': order_no })
    }

    const handleInvoiceSubmit = (e) => {
        e.preventDefault()
        updateInvoiceJob()

    }

    const updateInvoiceJob = async () => {
        try {
            const response = await WebService.post(apiEndPoints.jobInvoiceUpdate,
                JSON.stringify(input),
                {
                    headers: {
                        Authorization: `Bearer ${userData.token}`,
                    },

                });
            const { data } = response;
            // console.log(data.data)
            if (response.status === 200) {
                setIsInvoiceModal(false)
                setInvoice(data.data);
                setLoading(false);
                jobInvoiceShow()
                toast.success(response.data.msg, {
                    position: "top-center",
                });
                seterrorList([])

            } else if (response.response.status === 422) {
                // console.log(response.response.data.data)
                seterrorList(response.response.data.data)
            } else {
                setIsInvoiceModal(false)
                setLoading(false);
                seterrorList([])
            }

        } catch (e) {
            const error = sagaCatchBlockHandling(e);
            setIsInvoiceModal(false)
            setLoading(false);
            seterrorList([])

        }
    }

    const stringAndNumber = (string) => {
        let whole_string = string
        let split_string = whole_string.split(/(\d+)/)
        return split_string[1];
        // console.log("Text:" + split_string[0] + " & Number:" + split_string[1])
    }
    const stringAndNumber1 = (string) => {
        let whole_string = string
        let split_string = whole_string.split(/(\d+)/)
        return split_string[0];
        // console.log("Text:" + split_string[0] + " & Number:" + split_string[1])
    }

    const numberWithCommas = (a) => {
        const x = Number(a);
        return x.toString().split('.')[0].length > 3 ? x.toString().substring(0, x.toString().split('.')[0].length - 3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length - 3) : x.toString();
    }



    return (
        <>
            {isInvoiceModal && (
                <Modal
                    show={true}
                    size="lg"
                    onHide={() => setIsInvoiceModal(false)}
                    backdrop="static"
                    keyboard={false}
                    aria-labelledby="example-modal-sizes-title-lg"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title><span className="capitalize" style={{ color: "#009da6" }}></span> Update Invoice, if really required and both are agree.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleInvoiceSubmit}>
                            <div className="row form-row">

                                <div className="col-12 col-md-12">
                                    <div className="form-group">
                                        <input className="form-control capitalize" defaultValue={jobTitle} disabled />
                                    </div>
                                </div>
                                <div className="col-12 col-md-4">
                                    <div className="form-group">
                                        <label htmlFor="duration_type">Duration Type</label>
                                        <select name="duration_type" className="form-control select" onChange={handleInvoiceChange}>
                                            <option defaultValue="">Select you option</option>
                                            <option value="days">Days</option>
                                            <option value="week" >Weeks</option>
                                            <option value="month">Months</option>
                                            <option value="year">Years</option>
                                        </select>
                                        <ErrorP>{errorList?.duration_type}</ErrorP>
                                    </div>

                                </div>
                                <div className="col-12 col-md-4">

                                    <div className="form-group">
                                        <label htmlFor="duration_type">Duration</label>
                                        <input className="form-control" type="text" name="duration" onChange={handleInvoiceChange} />
                                        <ErrorP>{errorList?.duration}</ErrorP>
                                    </div>
                                </div>

                                <div className="col-12 col-md-4">

                                    <div className="form-group">
                                        <label htmlFor="payment_mode">Payment Mode</label>
                                        <input className="form-control" type="text" name="payment_mode" onChange={handleInvoiceChange} placeholder="Cash, Gpay, Online"/>
                                        <ErrorP>{errorList?.payment_mode}</ErrorP>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12">
                                    <div className="form-group">
                                        <label>
                                            Job Extra Amount
                                        </label>
                                        <input className="form-control" type="text" name="extra_amount" onChange={handleInvoiceChange} />
                                        <ErrorP>{errorList?.extra_amount}</ErrorP>
                                    </div>
                                </div>

                                <div className="col-12 col-md-12">
                                    <div className="form-group">
                                        <label>
                                            Job Notes
                                        </label>
                                        <textarea className="form-control" type="text" name="notes" onChange={handleInvoiceChange} placeholder="Notes: for extra work"/>
                                        <ErrorP>{errorList?.notes}</ErrorP>
                                    </div>
                                </div>
                                <p style={{ color: 'red' }}><strong>Notes: </strong>If you update the invoice. Last updated Invoice will be removed.</p>
                                <div className="submit-section">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-xs"
                                    // disabled
                                    >
                                        {loading ? (
                                            <Loader width="1.4rem" btn={true} />
                                        ) : (
                                            "Update Invoice"
                                        )}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            )}
            <Toaster />
            {/* <ModalComponent /> */}
            {loading ? (
                <Loader containerHeight={70} width="4rem" />
            ) : (
                <div className="main-wrapper">
                    <div className="breadcrumb-bar">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-md-12 col-12">
                                    <nav aria-label="breadcrumb" className="page-breadcrumb">
                                        <ol className="breadcrumb">
                                            <li className="breadcrumb-item">
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li
                                                className="breadcrumb-item active"
                                                aria-current="page"
                                            >
                                                <Link to="/user/dashboard">Job Invoice </Link>
                                            </li>
                                        </ol>
                                    </nav>
                                    <h2 className="breadcrumb-title">Job Invoice</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <SideBar
                                    profileData={profileData}
                                />
                                <div className="col-md-7 col-lg-8 col-xl-9">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <p className="capitalize">
                                                <Link to="/user/jobs/invoices"><i className="fas fa-long-arrow-alt-left"></i> <span>Back</span></Link>
                                            </p>
                                            <h4 className="mb-4 capitalize">{invoice?.job_approve?.job?.title}</h4>
                                            <div className="table-action pull-right">
                                                <button className="badge btn-primary" style={{ margin: "5px" }} onClick={Print_div}><i className="fas fa-print"></i> Print</button>
                                                <button className="badge btn-warning" style={{ margin: "5px" }} onClick={() => updateInvoice(invoice?.id, invoice?.job_approve?.job?.title)}><i className="fas fa-edit"></i> Update Invoice</button>
                                                {/* <button type="button" className="btn btn-secondary" style={{margin:"5px"}}><i className="fas fa-file-pdf"></i> Download Pdf</button> */}
                                            </div>
                                            <div  id='printablediv'>
                                                <div className="card card" id="serviceora_print_invoice">
                                                    <div className="card-body">
                                                        <div className="invoice-content">
                                                            <div className="invoice-item" style={{ padding: '20px' }}>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="invoice-logo">
                                                                            <img src={logoserviceora} alt="logo" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <p className="invoice-details">
                                                                            <strong>Order:</strong>   {invoice?.order_no}<br />
                                                                            <strong>Issued:</strong> {moment(invoice?.created_at).format("Do MMMM, YYYY hh:mm:ss a")}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="invoice-item" style={{ padding: '20px' }}>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="invoice-info">
                                                                            <strong className="customer-text">Invoice From</strong>
                                                                            <p className="invoice-details invoice-details-two">
                                                                                <span className="capitalize">{invoice?.job_approve?.customer?.name}</span> <br />
                                                                                {invoice?.job_approve?.job?.address}, {invoice?.job_approve?.job?.job_city?.name},<br />
                                                                                {invoice?.job_approve?.job?.job_state?.name}, {invoice?.job_approve?.job?.job_city?.description} {invoice?.job_approve?.job?.job_country?.name} <br />
                                                                                <mark>{invoice?.job_approve?.job?.job_code}</mark>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="invoice-info invoice-info2">
                                                                            <strong className="customer-text">Invoice To</strong>
                                                                            <p className="invoice-details">
                                                                                <span className="capitalize">{invoice?.job_approve?.service?.name}</span> <br />
                                                                                {invoice?.job_approve?.service?.correspondence_address}, {invoice?.job_approve?.service?.city?.name}, <br />
                                                                                {invoice?.job_approve?.service?.state?.name}, {invoice?.job_approve?.service?.city?.description}, {invoice?.job_approve?.service?.country?.name} <br />
                                                                                <mark>{invoice?.job_approve?.job_bidding?.bid_code}</mark>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="invoice-item" style={{ padding: '20px' }}>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="invoice-info">
                                                                            <strong className="customer-text">Payment Mode</strong>
                                                                            <p className="invoice-details invoice-details-two">
                                                                                {invoice?.payment_mode}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="invoice-item invoice-table-wrap" style={{ padding: '20px' }}>
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="table-responsive">
                                                                            <table className="invoice-table table table-bordered">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>Description</th>
                                                                                        <th className="text-center">Duration</th>
                                                                                        {/* <th className="text-center">VAT</th> */}
                                                                                        <th className="text-end">Total</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td><span className="capitalize">{invoice?.job_approve?.job?.title}, {invoice?.job_approve?.job?.job_sub_category?.name},</span><br /> ({invoice?.job_approve?.job?.job_code})</td>
                                                                                        <td className="text-center">{invoice?.job_approve?.job_bidding?.duration} {invoice?.job_approve?.job_bidding?.duration_key}</td>
                                                                                        {/* <td className="text-center">$0</td> */}
                                                                                        <td className="text-end">{invoice?.job_approve?.job_bidding?.amount_sign}{numberWithCommas(invoice?.job_approve?.job_bidding?.amount)}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td><strong style={{ color: 'red' }}>Note:</strong> {invoice?.notes}</td>
                                                                                        <td className="text-center">{invoice?.total_duration}</td>
                                                                                        {/* <td className="text-center">$0</td> */}
                                                                                        <td className="text-end">
                                                                                            {
                                                                                                invoice?.extra_amount ?
                                                                                                    <>
                                                                                                        {invoice?.job_approve?.job_bidding?.amount_sign} {numberWithCommas(invoice?.extra_amount)}
                                                                                                    </>
                                                                                                    : ''
                                                                                            }
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 col-xl-4 ms-auto">
                                                                        <div className="table-responsive">
                                                                            <table className="invoice-table-two table">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th>Subtotal:</th>
                                                                                        <td><span>{invoice?.job_approve?.job_bidding?.amount_sign} {numberWithCommas(invoice?.job_approve?.job_bidding?.amount + Number(invoice?.extra_amount))}</span></td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                                                <th>Service Charges (0%):</th>
                                                                                                                <td><span>{invoice?.job_approve?.job_bidding?.amount_sign} 0</span></td>
                                                                                                            </tr>
                                                                                    <tr>
                                                                                        <th>Total Amount:</th>
                                                                                        <td><span>{invoice?.job_approve?.job_bidding?.amount_sign} {numberWithCommas(invoice?.job_approve?.job_bidding?.amount + Number(invoice?.extra_amount))}</span></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className="other-info" style={{ padding: '20px' }}>
                                                                <h4>Other information</h4>
                                                                <p className="text-muted mb-0">The invoice may include specific terms and conditions related to the services provided, such as cancellation policies, refund policies, or any contractual agreements between the service provider and the customer.</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}